<template>
    <div>
		<CustomTable
			ref="table"
			id_table="note_selection"
			:items="local_items"
			primaryKey="note_id"
			:busy.sync="table_busy"
			:selectMode="unique ? 'single' : 'multi'"
        	:selected_ids.sync="local_selected"
		/>

		<div class="row">
			<div class="text-center mt-3">
                <button @click="closeSelection" class="btn btn-secondary rounded-pill mr-4">
                    <font-awesome-icon :icon="['fal', 'times']" /> {{ $t('global.close') }}
                </button>
            </div>
		</div>
    </div>
</template>

<script>
import Shutter from "@/mixins/Shutter.js"

export default {
	name: 'NoteSelection',
    mixins: [Shutter],
    props: {
		notes: { type: Array, default: () => [] },
        selected: { type: Array, default: () => ([]) },
		unique: { type: Boolean, default: false }
    },
    data() {
        return {
			local_items: [],

            /* Table config : */
            table_busy: true,
			local_selected: []
        }
	},
	created() {
		this.preinit_component()
	},
    mounted() {
        this.init_component()
    },
    methods: {
		async preinit_component() {
			if(this.notes.length > 0) {
				this.local_items = this.notes
				this.table_busy = false
			}
		},
		init_component() {
			this.local_selected = this.selected
		},
		closeSelection() {
			this.shutterPanel().keepLast()
		}
    },
	watch: {
		local_selected(val) {
			this.$emit('update:selected', val)
			
			if (val.length) {
				this.ok(val)
			}
		}
	},
    components: {
        CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
    }
}
</script>
